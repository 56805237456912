$font-path: "assets/fonts";
$font-name: "sofiapro-light.otf";

@font-face {
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/SofiaPro/sofiapro-light.otf") format("opentype");
  font-display: swap;
}

html,
body {
  font-family: "Sofia Pro", sans-serif !important;
}

.body-bold-text {
  font: normal normal bold 13px/24px Sofia Pro !important;
}

.button-text,
h4 {
  text-align: left !important;
  font: normal normal bold 16px/24px Sofia Pro !important;
  letter-spacing: 0 !important;
}

.body-text-1 {
  font: normal normal normal 16px/24px Sofia Pro !important;
  letter-spacing: 0 !important;
  color: #33383e;
}

.body-text-2 {
  font: normal normal normal 12px/24px Sofia Pro !important;
  letter-spacing: 0 !important;
}

.text-color-black {
  color: #000 !important;
}

.text-color-white {
  color: #fff !important;
}

.text-color-primary {
  color: #f1be7c !important;
}

.text-color-gray {
  color: #969798 !important;
}

.tex-color-dark-gray {
  color: #33383e !important;
}

.text-color-green {
  color: #274d4f !important;
}

.text-color-red {
  color: #bb2d25 !important;
}

h1 {
  font: normal normal 700 32px/24px Sofia Pro !important;
}

.layout-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media (min-width: 768px) {
  .layout-container {
    max-width: 704px;
  }
}

@media (min-width: 896px) {
  .layout-container {
    max-width: 832px;
  }
}

@media (min-width: 1024px) {
  .layout-container {
    max-width: 960px;
  }
}

@media (min-width: 1152px) {
  .layout-container {
    max-width: 1088px;
  }
}

@media (min-width: 1280px) {
  .layout-container {
    max-width: 1216px;
  }
}

@media (min-width: 1536px) {
  .layout-container {
    max-width: 1472px;
  }
}

.padding-top-48 {
  padding-top: 48px;
}

.padding-right-64 {
  padding-right: 64px;
}

.padding-left-138 {
  padding-left: 138px;
}

.padding-left-100 {
  padding-left: 100px;
}

.padding-top-50 {
  padding-top: 50px;
}

.margin-right-40 {
  margin-right: 40px !important;
}

.margin-top-48 {
  margin-top: 48px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-top-96 {
  margin-top: 96px !important;
}

.margin-top-16 {
  margin-top: 16px;
}

.width-100 {
  width: 100% !important;
}

.container {
  margin-left: 148px;
  margin-right: 148px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
}

.flex-row-start-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
}

.primary-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #f1be7c;
  text-align: center;
}

.selected-header {
  color: #f1be7c;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.padding-bottom-32 {
  padding-bottom: 32px;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-field {
  border-color: #dedede;
  border-radius: 8px;
}

.display-none {
  display: none !important;
}

.width-25 {
  width: 25% !important;
}

.width-75 {
  width: 75% !important;
}

.width-50 {
  width: 50% !important;
}

.border-radius-16 {
  border-radius: 16px;
}

.gap-xxs {
  gap: 4px;
}

.gap-xs {
  gap: 8px;
}

.gap-s {
  gap: 12px;
}

.gap-sm {
  gap: 16px;
}

.gap-m {
  gap: 24px;
}

.gap-l {
  gap: 32px;
}

.gap-xl {
  gap: 48px;
}

.gap-xxl {
  gap: 64px;
}

.logo-background {
  width: 130px;
  height: 130px;
  background: #dedede 0 0 no-repeat padding-box;
  border-radius: 65px;
}

.remove-decoration {
  color: #ffffff;
  text-decoration: none;
}

.form-flex {
  width: 100%;
}
.image-contain {
  object-fit: contain;
}
.rounded-image {
  border-radius: 50px;
}

.bold {
  font-weight: bold !important;
}

.ellipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.page-min-height {
  // Page min height is equal to total height minus header and footer
  min-height: calc(100vh - 100px - 200px);
}

.parallax-page-min-height {
  // Page min height is equal to total height minus parallax header and footer
  min-height: calc(100vh - 400px - 200px);
}

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.image-radius {
  border-radius: 16px;
  object-fit: cover;
}

.min-width-100 {
  min-width: 100px !important;
}

.terms {
  ul {
    list-style-type: disc;
  }
  li::marker {
    color: #f1be7c;
  }

  .ql-indent-2::marker {
    color: black;
  }
  .ql-indent-2 {
    list-style-type: "~";
  }

  .ql-indent-1::marker {
    color: black;
  }
  .ql-indent-1 {
    list-style-type: square;
  }
}

.without-decoration {
  text-decoration: none;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  border-radius: 16px;
}

.mainSwiper {
  height: 80%;
  width: 100%;
  margin-bottom: 24px;
}

.thumbsSwiper {
  height: 20%;
  box-sizing: border-box;
}

.thumbsSwiper .swiper-slide {
  width: 25%;
  height: 97%;
}

.thumbsSwiper .swiper-slide-thumb-active {
  opacity: 1 !important;
  border: 2px solid #f1be7c !important;
}

.mainSwiper .swiper-slide img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 16px;
}

.thumbsSwiper .swiper-slide img {
  border-radius: 16px;
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  color: white !important;
  height: 32px !important;
  width: 32px !important;
  border: 1px solid #ffffff;
  border-radius: 50%;
  &:hover {
    background-color: #333333;
  }
  &:after {
    display: none;
  }
}

.swiper-button-prev {
  background-image: url("/assets/buttons/left-arrow.svg") !important;
  background-repeat: no-repeat;
  background-size: 8px auto;
  background-position: center;
}

.swiper-button-next {
  background-image: url("/assets/buttons/right-arrow.svg") !important;
  background-repeat: no-repeat;
  background-size: 8px auto;
  background-position: center;
}

.highlight {
  animation: fadein 3s infinite;
  -webkit-animation: fadein 3s infinite;
  -moz-animation: fadein 3s infinite;
  -o-animation: fadeIn 3s infinite;
  width: 100%;
  border-radius: 8px;
  height: 156px;
}

@keyframes fadein {
  from {
    background-color: lightgrey;
  }
  to {
    background-color: white;
  }
}

//
